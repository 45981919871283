@import-normalize;

@font-face {
  font-family: 'poppins';
  src: url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins';
  src: url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'poppins';
  src: url('../fonts/Poppins/Poppins-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'poppins';
  src: url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: 'poppins', '-apple-system', 'Arial', 'sans-serif';
  font-size: 1em;
  line-height: 1.5;
  margin: 0;
  background-color: #f2f2f2;
  color: #4E4E4E;
}

main {
  flex: 1;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  border: 0;
}
